.item-list-card {
    background-color: white;
    padding: clamp(10px, 1vw, 25px);
    border-radius: 9px;
    width: 100%;
    &:not(:last-child){
        margin-bottom: 10px;
    }
}
.item-card {
    background-color: white;
    padding: clamp(10px, 1vw, 25px);
    border-radius: 9px;
    width: 100%;
    
}
.card-label {
    font-weight: 500;
    font-size: 15px;
}
.indent-top {
    margin-top: 10px;
}
.clicable {
    user-select: none;
    cursor: pointer;
    
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    }

    &:active {
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    }
}

.title-list-card {
    font-size: clamp(15px, 1vw, 30px);
    font-weight: 600;
}
