.form-option-item {
    &__divider {
        display: flex;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        align-items: center;
    }

    &__inputs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__input {
            width: 47%;
        }
    }
}
