.start-menu-item {
    height: 50px;
    display: flex;
    padding: 15px;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;
    align-items: center;
    font-size: 14px;
    justify-content: flex-start;

    &:not(:last-child) {
        border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    }

    &:hover {
        background-color: #00000005;

        .start-menu-item__label {
            font-size: 16px;
        }
    }

    &:active {
        background-color: #00000003;
        transition: all 1ms;

        .start-menu-item__label {
            font-size: 15px;
        }
    }

    &__label {
        transition: all 400ms;

        margin-left: 15px;
    }
}
