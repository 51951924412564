.tm-operation {
    width: 100%;
    margin-right: 10px;
    display: flex;
    justify-content: center;

    &:last-child{
        margin-right: 0px;
    }
    &__content{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 100%;
        padding: 8px;
        display: flex;
        justify-content: space-between;
    }
    &__arrow, &__content {
        -webkit-transition: background-color 0.3s ease-out;
        -moz-transition: background-color 0.3s ease-out;
        -o-transition: background-color 0.3s ease-out;
        transition: background-color 0.3s ease-out;
    }
    &__arrow {
        margin-left: -2px;
        padding: 8px;
        -webkit-clip-path: polygon(30% 0, 100% 50%, 30% 100%, 0 100%, 0 0, 0 0);
        clip-path: polygon(30% 0, 100% 50%, 30% 100%, 0 100%, 0 0, 0 0);        
    }
}


.tm-operations{
    -webkit-box-shadow: 0 0 7px -2px #00000030;
    -moz-box-shadow: 0 0 7px -2px #00000030;
    box-shadow: 0 0 7px -2px #00000030;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    &__header{
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
        
        @media (max-width: 880px) {
            flex-direction: column;
        }

        &__name{
            margin-left: 10px;
            font-weight: 500;
            font-size: 15px;
        }
    }
    &__items{
        display: flex;

    }
}


.tm-skeleton .ant-skeleton-paragraph > li {
    padding-top: 0px !important;
}
.tm-operation-skeleton .ant-skeleton-paragraph > li {
    padding-top: 0px !important;
    height: 35px !important;
}

