.bizproc-dashboard-item {
    &__title {
        padding: 8px;
        font-weight: 600;
        font-size: 18px;
    }
    &__description {
        font-size: 15px;
        color: #8c8c8c;
    }
}
