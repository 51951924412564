.user-roles {
    margin-right: 4px;

    flex: 0;

    &__nav {
        display: flex;
        position: relative;
        height: 100%;
        z-index: 998;
        border-radius: 8px;
    }

    &__list {
        border-radius: 8px;
        background: white;
        flex: 1 1 auto;
        -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);

        & * {
            white-space: nowrap;
            overflow: hidden;
        }

        & .ant-spin-nested-loading {
            border-radius: 8px !important;
            box-sizing: border-box !important;
            overflow: auto !important;
        }

        & .ant-spin-nested-loading .ant-spin-container {
            height: 100%;
        }
    }

    &__motion-button {
        position: absolute;
        top: 0;
        bottom: 0;
        right: -8px;
        margin-top: auto;
        margin-bottom: auto;
        z-index: 999;
        width: 16px !important;
        height: fit-content;
        color: white;
        border: none;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
        background: var(--info);
    }

    &__list-container {
        overflow: auto;
        height: 100%;
        min-width: 300px;
        width: 100%;
    }
}

.spin-container-user-roles {
    height: 100%;
}