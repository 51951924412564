.actions-card {
    background-color: white;
    width: 100%;
    padding: 18px;
    border-radius: 10px;
    position: relative;
}

.clickable {
    cursor: pointer;
}