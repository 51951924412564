.agree-item {
    display: flex;
    flex-direction: column;
    padding: 15px;
    justify-content: stretch;
    background-color: white;
    border-radius: 9px;
    font-size: 14px;
    position: relative;
    height: 100%;

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    &__tag {
        display: flex;
        height: fit-content;
    }

    &__title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 0;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 500;
        color: #4f4f4f;
    }

    &__tag > .ant-tag {
        text-align: center;
        height: fit-content;
    }

    &__desc {
        color: #4f4f4f;
        width: fit-content;
    }

    &__assigned-user > .agree-item__user {
        display: flex;
        background-color: #eeeeee;
        padding: 4px;
        margin-left: 8px;
        border-radius: 4px;
        cursor: pointer;
    }

    &__assigned-user &__footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 30px;
    }

    &__footer {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: flex-end;
        // align-items: flex-end;
    }

    &__cooment-date {
        display: flex;
        flex-direction: column;
        width: 60%;
        color: #4f4f4f;
    }

    &__date {
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__date > span {
        color: #4f4f4f;
    }

    &__comment > span {
        color: #4f4f4f;
    }

    &__comment {
        margin-bottom: 10px;
        width: 100%;
        color: #979797;
        white-space: nowrap;
    }

    &__additional {
        margin-top: 10px;
        color: #979797;
        text-wrap: nowrap;
        text-decoration: underline;
        text-align: end;
    }

    &__additional > span {
        cursor: pointer;
    }
}
