.times {
    display: flex;
    justify-content: space-between;

    @media (max-width: 475px) {
        flex-direction: column;
    }

    &__input {
        @media (max-width: 475px) {
            margin-bottom: 10px;
            width: 100%;
        }
    }
}

.format {
    @media (max-width: 475px) {
        display: block;
        width: 60px;
    }
}
