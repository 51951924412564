.bookmark {
    position: absolute;
    left: 0px;
    top: 30px;
    padding: 10px;
    font-size: 15px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    background-color: white;
    transform: translateX(-100%);
    transition: all 300ms ease;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    cursor: pointer;

    &__close {
        padding: 2px;
        margin-right: 4px;
        border: 1px solid rgba(0, 0, 0, 0);
    }

    &:hover {
        .bookmark__close {
            border: 1px solid var(--primary-5);
            border-radius: 50%;
        }
    }
}