.stages-final-cards { 
    display: flex;
    margin-bottom: 10px;
    #successful{
        margin-right: 20px;
    }
    @media (max-width: 520px) {
        flex-wrap: wrap;
        #successful{
            margin-bottom: 20px;
        }
    }

}