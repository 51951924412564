.card-list {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    background-color: white;
    box-sizing: border-box;
    border-radius: 20px;
    height: 100%;
    min-height: 300px;
    width: 100%;
    overflow-y: hidden;

    &__items {
        padding: 20px;

        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__header {
        padding: 10px 20px;
        border-bottom: 1px solid rgba(5, 5, 5, 0.06);
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 5px;
    }
}
