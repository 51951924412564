@import "../../../styles/Colors.scss";

.card-error {
    background: white;
    width: 300px;
    border-radius: 8px;
    overflow: hidden;

    &__title {
        display: flex;
        justify-content: space-between;
        padding: 18px;
        width: 100%;
        background: rgb(255, 80, 80);
        font-size: 18px;
        color: white;
        font-weight: 500;
    }

    &__content {
        height: 100px;
        font-size: 16px;
        padding: 18px;
    }
}

