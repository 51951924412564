.user-profile {
    // padding: 20px 20px 0 20px;
    width: 100%;
    max-width: 450px;
    @media (max-width: 475px) {
        padding: 0 8px 0 8px;
    }
}

.user-profile-card {
    background: white;

    height: fit-content;
    border-radius: 8px;
    padding-bottom: 48px;
    width: 100%;
    @media (max-width: 475px) {
        height: 100%;
        padding-bottom: 0;
        max-width: none;
        width: 100%;
    }

    &__actions {
        display: flex;
        justify-content: center;
    }

    &__actions button {
        font-size: 14px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        height: 80%;
       
    }

    &__header {
        display: flex;
        height: fit-content;
        flex-direction: column;
        align-items: center;
    }

    &__avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28;
        background: #f4f4f4;
        height: 100px;
        width: 100px;
        font-size: 32px;
        border-radius: 50%;
        margin-top: 32px;
        margin-bottom: 24px;
    }

    &__body {
        display: flex;
        flex-direction: column;
        // flex-grow: 1;
    }

    &__info-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    &__info-item-container {
        display: flex;
        font-size: 16px;
        align-items: center;
        @media (max-width: 500px) {
            align-items: flex-start;
            width: 100%;
            flex-direction: column;
        }
    }

    &__item {
        display: flex;
    }

    &__item--label {
        width: 50%;
        color: gray;
        align-items: center;
        @media (max-width: 500px) {
            width: 100%;
        }
    }
    
    &__item--value {
        width: 50%;
        overflow-wrap: anywhere;
        padding-right: 16px;
        @media (max-width: 500px) {
            width: 100%;
        }
    }
}