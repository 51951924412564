.kanban-toolbar {
    & button {
        background: rgb(143, 133, 232, 0.2) !important;
        color: var(--primary);
        border: none;

        &:hover {
            background: var(--primary) !important;
            color: white !important;
            opacity: 1 !important;
        }
    }
}