.user-agreements-list {
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    max-width: 900px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 45%;
    min-height: 300px;
    border-radius: 8px;
    box-sizing: content-box;
    &__header {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 5px;
    }
}
