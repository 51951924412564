.entity-rows-view-toolbar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    &__category-select {
        min-width: 160px;

        @media (max-width: 566px) {
            flex-grow: 1;
        }
    }
    
    &__filters {
        flex-grow: 1;
        min-width: 200px;
    }
}


.entity-name-in-header {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: fit-content;
    min-width: 1ch;
    white-space: nowrap;
    @media (max-width: 566px) {
        width: 240px;

    }

    
}
