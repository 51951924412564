.tmt-operation {
        width: 100%;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        &:hover {
            .tmt-operation__arrow, .tmt-operation__content {
               background-color: hsl(from var(--primary-card) h s 95%);;
            }
            
        }
        &:last-child{
            margin-right: 0px;
        }
        &__content{
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            width: 100%;
            padding: 8px;
            background-color: var(--primary-card);
            display: flex;
            justify-content: space-between;
        }
        &__arrow, &__content {
            -webkit-transition: background-color 0.3s ease-out;
            -moz-transition: background-color 0.3s ease-out;
            -o-transition: background-color 0.3s ease-out;
            transition: background-color 0.3s ease-out;
        }
        &__arrow {
            background-color: var(--primary-card);
            margin-left: -2px;
            padding: 8px;
            -webkit-clip-path: polygon(30% 0, 100% 50%, 30% 100%, 0 100%, 0 0, 0 0);
            clip-path: polygon(30% 0, 100% 50%, 30% 100%, 0 100%, 0 0, 0 0);        
        }
}