.qr-codes-chunk-container {
    display: none;
    flex-direction: row;
    width: fit-content;
    box-sizing: border-box;

    &__body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: content-box;
        margin-left: 16px;
    }

    &__item-label {
        text-align: center;
        white-space: balance;
    }
}