.card-avatar{
    display: flex;
    position: relative;
    align-items: center;
    padding: 8px;
    background-color: var(--primary-card);
    border:1px solid var(--border-primary);
    &__avatar{
        margin-right: 8px;
    }
    &__info {
        width: 100%;

        display: flex;
        flex-direction: column;
        &__header{
            font-size: 14px;
        }
        &__desc{
            font-size: 11px;
            color: var(--secondary-text)
        }
    }
}

.card-avatar .clickable {
    cursor: pointer;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    }
}