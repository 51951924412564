.stage-list-item{
    display: flex;
    cursor: pointer;
    
    &:hover{
        // margin-top:-10px;
        // z-index: 1111;
        // transform: scale(1.1);
        // position: relative;
    }
    &__content{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        &__name{
            margin-right: 10px;
            mix-blend-mode:difference;
            color:#998e95;
        }
    }

    &__prev-arrow {
        margin-right: -2px;
        padding: 10px;
        clip-path: polygon(100% 0%, 100% 100%, 100% 100%, 0% 100%, 80% 50%, 0% 0%);
        
    }
    &__next-arrow {
        margin-left: -2px;
        padding: 10px;
        clip-path: polygon(20% 0%, 100% 50%, 20% 100%, 0% 100%, 0% 0%, 0% 0%);

    }

}
