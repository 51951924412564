.entity-rows-kanban .ant-modal {
    position: relative !important;
    height: 100vh !important;
    width: 100vw !important;
    max-width: none !important;
    padding: 0 !important;
    margin: 0 !important;
    top: 0 !important;
}

.entity-rows-kanban .ant-modal-content {
    position: relative !important;
    padding: 16px !important;
    height: 100% !important;
    width: 100% !important;
}

.entity-rows-kanban {
    width: 100%;
}