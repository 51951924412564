.entity-list-card-field {
    font-size: 14px;
    display: flex;
    margin-bottom: 3px;
}
.entity-list-card-field-value{
    color:var(--dark);
}
.entity-list-card-field>div>.select-value {
    min-height: 0;
}

.entity-list-card-field-column {
    width: 50%;
    box-sizing: border-box;
    color:rgba(0, 0, 0, 0.45);
    font-weight: normal;
    font-size: var(--ant-font-size);
    line-height: var(--ant-line-height);
    text-align: start;
    &.-value {
        margin-left: 8px;
    }
    
}

