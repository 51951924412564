.user {
    display: flex;
    flex-direction: column;
    padding: 15px;
    &__info{
        display: flex;



        &__avatar-card{
            margin-right: 10px;
            margin-bottom: 10px;
            width: fit-content;
            height: fit-content;
            padding: 30px;
            &__label{
                display: flex;
                align-items: center;
                justify-content: center;
                background: #f4f4f4;
                height: clamp(150px, 10vw, 200px);
                width: clamp(150px, 10vw, 200px);;
                font-size: clamp(35px, 10vw, 42px);;
                border-radius: 50%;
                
            }
        }

        @media (max-width: 800px) {
            flex-direction: column;
            &__avatar-card{
                padding: 0px;
             margin-right: 0px;
             width: 100%;
             display: flex;
             justify-content: center;
             background-color: transparent;
             &__label{
                    background-color: white;
                    aspect-ratio: 1 / 1;
                    border-radius: 50%;
                    font-size: 35px;
                 }
            }
         }

    }
}