body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}
html,body {
    height:100%;
}
:root {
    --primary: #8F85E8;
    --primary-card: #f7f7fd;
    --primary-5: hsl(from var(--primary) h s 80%);
    --primary-4: hsl(from var(--primary) h s 85%);
    --primary-3: hsl(from var(--primary) h s 90%);
    --primary-2: hsl(from var(--primary) h s 95%);
    --primary-1: hsl(from var(--primary) h s 99%);
    --info: #cbaaff;
    --dark: #001529;
    --danger: #ff4d4f;
    --card-border-radius: 8px;
    --accent: #3aafb1;
    --secondary-text: #a1a1a1;
    --border-primary: hsl(from var(--primary-card) h s 95%);
    --border-primary-2: #00a8b133;
    --scrollbarcolor: rgba(0, 0, 0, 0.12);
}