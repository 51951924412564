.start-actions {
    background-color: white;
    border-radius: 8px;
    width: 100%;
    height: fit-content;
    max-width: 270px;
    margin: 0px 10px;

    @media (max-width: 1024px) {
        order: 2;
    }

    &__header {
        padding: 10px;
        font-weight: 600;
    }

    &__statements {
        padding: 8px;
        background-color: white;
        font-size: 14px;
        border-radius: 8px;
        width: fit-content;
    }
}
