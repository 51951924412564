.entity-rows-table-pattern-column__value-container {
    width: fit-content;
    box-sizing: border-box;


}

.entity-rows-table-pattern-column__label {
    font-weight: 400;
    color: #8b8b8b;

}

.entity-rows-table-pattern-column__descriptions {
    & .ant-descriptions-item {
        padding: 0 !important;
    }

    & .ant-descriptions-item-label {
        width: 50%;
        word-break: break-all;
    }
}