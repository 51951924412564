.dashboard {
    box-sizing: content-box;
    display: flex;
    height: 100%;

    //TODO: переписать с использованием resizeHooks

    // @media (max-width: 1023px) and (orientation: portrait) {
    //     flex-direction: column;
    //     padding: 10px;

    // }

    // @media (max-width: 1023px) and (orientation: landscape) {
    //     flex-direction: column;
    // }

    &__actions-lists {
        display: flex;
        width: 60%;
        max-width: 900px;
        flex-direction: column;

        @media (max-width: 1024px) {
            width: 100%;
        }

        @media (orientation: landscape) {
            height: calc(100vh - 130px);
            width: 100%;
        }
    }

    &__actions-lists:first-child {
        margin-bottom: 16px;

        @media (max-width: 810px) {
            margin-bottom: 0;
        }

        @media (orientation: landscape) {
            margin-bottom: 0;
        }
    }

    &__actions-lists--mobile {
        flex-direction: column;
        order: 2;
        overflow: auto;
        height: 100%;

        & .ant-tabs,
        .ant-tabs-tabpane,
        .ant-tabs-content,
        .ant-tabs-content-holder {
            height: 100% !important;
        }
    }


    &__actions-list:first-child {
        margin-bottom: 16px;
    }
}