.entity-rows-format-tabs {
    height: 100%;

    & .ant-tabs-content-top {
        height: 100%;
    }

    & .ant-tabs-tabpane {
        height: 100%;
        display: flex;
    }

    @media (max-width: 768px) {
        & .ant-tabs-tab {
            padding: 4px;
        }

        & .ant-tabs-nav {
            margin: 0 0 8px 0 !important;
        }
    }

}