.form-item {
    margin-bottom: 12px; // TODO MAX
    width: 100%;

    &__label {
        font-size: 14px;
        margin-bottom: 5px;
        color: #838383;
        font-weight: 400;
    }

    &__input {}

    &__error {
        color: red;
        font-size: 12px;
        margin-top: -3px;
        margin-bottom: 0px;
    }
}