.login-page{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__signin{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 400px;
        width: 100%;
        border: 1px solid var(--border-primary);
        border-radius: 8px;
        padding: 40px 35px 25px !important;
        box-sizing: border-box;
    }
    &__remember{
        margin-top: 10px;
    }
}