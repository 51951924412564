.bizprocs-title {
    padding: 10px 20px;
}

.bizprocs .ant-dropdown-menu-item {
    padding: 0;
}

.bizprocs {
    width: 100%;
    padding: 10px;

    &__list {
        display: flex;
        flex-wrap: wrap;

        &__empty {
            background-color: white;
            border-radius: 8px;
            padding: 30px;
            width: 100%;
        }
    }

    &__item-icon-status {
        margin-right: 8px;
    }

    &__item-title {
        display: flex;
        align-items: center;
    }

    &__item {
        width: 400px;
        min-width: 290px;
        box-sizing: border-box;
        margin-right: 16px;
        margin-bottom: 16px;

        @media (max-width: 475px) {
            margin-right: 0;
        }
    }

    &__title {
        font-size: 22px;
        border-radius: 8px;
        font-weight: 500;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    &__item-title-link {
        text-decoration: underline;
        cursor: pointer;
        font-size: 18px;

        @media (max-width: 475px) {
        }
    }

    &__item-title-link:hover {
        color: #2563ff;
    }
}
