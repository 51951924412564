@import "@shared/styles/Colors.scss";


.header-title-of-layout {
    display: flex;
    align-items: center;
    width: fit-content;
}

.header {
    display: flex;
    padding: 0;
    border-bottom: 1px solid #e1e1e15c;

    &__title-container {
        margin-left: 20px;
        height: 100%;
        line-height: normal;
        display: flex;
        flex-direction: column;
        justify-content: center;
        animation: fadeIn ease 1s;
        text-wrap: nowrap;
        text-overflow: ellipsis;
    }

    &__title {
        display: flex;
        font-size: clamp(17px, 3vw, 26px);
        font-weight: 600;
        align-items: center;
    }

    &__subtitle {
        color: $secondary-text;
    }

    background: #fff;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.ant-avatar-string {
    font-size: 30px;
}

.entity-name-in-header {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: fit-content;
    min-width: 1ch;
    white-space: nowrap;

    @media (max-width: 1120px) {
        width: 240px;
    }
}