.actions-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media (max-width: 475px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    &__item {
        margin-right: 10px;
        margin-bottom: 10px;

        @media (min-width: 963px) {
            width: 300px;
        }

        @media (min-width: 652px) and (max-width: 962px) {
            flex-grow: 1;
            width: 300px;
        }

        @media (min-width: 476px) and (max-width: 651px) {
            flex-grow: 1;
            min-width: 220px;
        }

        @media (max-width: 475px) {
            flex-grow: 1;
            min-width: 220px;
            max-width: 400px;
            width: 100%;
            margin-right: 0;
        }
    }

    // &__item:nth-child(even) {
    //     @media (min-width: 486px) and (max-width: 702px) {
    //         margin-right: 0;
    //     }

    // }
}
