.entity-fields-filter {
    & .filter-fields {
        padding: 0 !important;
        flex: 1 1 100% !important;

        & .filter-fields__body {
            gap: 8px 8px !important;
            padding: 0 !important;
        }
    }

    & .form-item {
        margin-bottom: 0 !important;
        flex: 1 1 100% !important;

    }

    & .filter-fields__item {
        margin-right: 0 !important;

        & .ant-btn {
            flex: 1 1 100% !important;
            margin-top: 0 !important;

            @media (min-Width: 620px) {}
        }
    }
}