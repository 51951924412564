.entity-page {
    height: 100%;
    overflow: auto;
    scrollbar-gutter: stable both-edges;

    & .ant-tabs-content {
        height: 100%;
    }
}

.tab-content-container {
    width: 100%;
    overflow: auto;
    height: fit-content;
    max-height: 100%;
}