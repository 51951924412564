.rows-actions {
    display: flex;
    align-items: center;
    width: 100%;
    flex: 0 1100px;

    .ant-select-selection-overflow {
        @media (min-width: 1050px) {
            flex-wrap: nowrap !important;
            width: 90% !important;
        }
    }

    .ant-select-selection-placeholder {
        @media (min-width: 1050px) {
            max-width: 85% !important;
        }
    }

    & .slick-arrow {
        color: red !important;
    }

    & .ant-divider-vertical {
        border-inline-start: 1px solid gray !important;
        height: 24px !important;
    }

    &__count {
        display: flex;
        text-wrap: nowrap;
        font-size: 14px;
    }


}