.assigned-user:first-child {
    margin-left: 4px;
}

.assigned-user {
    display: flex;
    align-items: center;
    width: fit-content;
    text-wrap: nowrap;
    margin-right: 4px;
    margin-left: 0;

    @media (max-width: 475px) {
        flex-wrap: wrap;
    }


    &__user {
        display: flex;
        background-color: #eeeeee;
        padding: 4px;
        border-radius: 4px;
        cursor: pointer;
        text-wrap: nowrap;

        @media (max-width: 475px) {
            margin-left: 0;
        }
    }
}

.user-assygnment-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__status {
        width: fit-content;
    }

    &__user-lists {
        display: flex;
        flex-wrap: wrap;
    }
}

.user-assygnment-list--dropdown {
    display: flex;
    align-items: center;
}