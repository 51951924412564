.list-with-animation {

    .ant-list-item-meta-avatar {
        margin-left: 8px;
    }

    &__item {
        position: relative;
        flex-grow: 1;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition-duration: 0.2s;
        border-radius: 8px;

        &:hover {
            background: var(--primary-3);
            scale: 1.05;

            & h5 {
                color: var(--primary);
            }
        }
    }
}



.list-with-animation__item-addon {

    &:hover {
        color: white !important;
        font-weight: 600 !important;
    }

}