.column-container {
    margin-right: 16px;
    border-radius: 16px;
    width: 300px;
    height: 90%;
    flex-grow: 1;
}

.items-container {
    padding: 16px 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: #ebebeb;
    margin-top: 8px;
    border-radius: 16px;
    height: 90%;
}

.item-container {
    margin-top: 16px;
}

.item-container:first-child {
    margin-top: 0px;
}

.item-container:last-child {
    margin-bottom: 0;
}

.column-title {
    box-sizing: border-box;
    padding: 8px;
    font-size: 18px;
    font-weight: 500;
    color: white;
    padding-left: 16px;
    border-radius: 8px;
}

.column-spinner {
    display: flex;
    justify-content: center;
    padding: 24px;
}

.column-item {
    margin-bottom: 8px;
    background: white;
    -webkit-box-shadow: 0px 1px 4px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 1px 4px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 1px 4px -3px rgba(0, 0, 0, 0.75);
}

.column-container {
    width: 100%;
    border-radius: 16px;
    background: rgb(243, 243, 243, 0.5);
    height: 90%;
    border-radius: 8px;
    position: relative;
}

.column-items {
    height: calc(100% - 16px);
    display: flex;
    flex-direction: column;
    position: relative;
}