.kanban-item {
    background: none;
    padding: 8px;


    & .select-value.form-read-value {
        height: 24px !important;
        min-height: 24px !important;
    }

    & .form-item__label {
        margin: 0;
        color: rgba(156, 156, 156, 0.575);
    }

}