.bar {
    background-color: white;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 7px -2px #00000030;
    -moz-box-shadow: 0 0 7px -2px #00000030;
    box-shadow: 0 0 7px -2px #00000030;
    border-radius: 10px;
    &__header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px;
        border-bottom: 1px solid #afafaf55;
        
        &__label{
            font-weight: 500;
        }
    }  
    &__content{
       overflow-y: auto;
       height: 100%;
        &__items{
            display: flex;
            flex-direction: column;
        }
        &__skeleton{
            padding: 15px 0px;
            margin:0px 10%;
        }
    }
} 



.bar-item {
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    display: flex;
    &__label{
        text-wrap: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &:hover{
        background-color: #00000008;
    }
    &:active{
        background-color: #00000005;
    }
    &__active { 
        color:var(--primary)
    }
}