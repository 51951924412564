.entity-value-filter {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    flex: 1 1 fit-content;
    border-radius: 8px;
    border: 1px solid #cccccc;
    background: white;
    padding: 4px !important;
    flex-wrap: wrap;

    &__saved-filter {
        box-sizing: border-box;
        display: flex;
        height: fit-content;
        align-items: center;
        position: relative;
        width: fit-content;
        color: rgb(255, 255, 255);
        padding-left: 8px;
        padding-right: 24px;
        border-radius: 4px;
        align-items: center;

    }

    &__saved-filter-item {
        display: flex;
        align-items: center;
        width: max-content;
        box-sizing: border-box;
        padding: 0.3rem 0.1rem;
        height: 30px;


        & .form-read-value {

            @media (max-width: 1440px) {
                max-width: 60px;
                text-wrap: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }



        @media (max-width: 768px) {
            flex: 1 1 10px !important;
        }

    }

    &__global-input {
        display: flex;
        flex: 1 1 100px !important;
        min-width: 100px !important;
        height: 30px !important;
        box-sizing: border-box;
        align-items: center;

        @media (max-width: 700px) {
            flex: 1 1 50px !important;
            min-width: 0 !important
        }
    }

    &__global-input>input {
        padding: 4px !important;
        height: 22px !important;
    }

    &__entity-fields-inputs {
        box-sizing: border-box;
        padding: 32px;
        position: absolute;
        z-index: 999;
        background: white;
        -webkit-box-shadow: 0 0 15px -2px #00000030;
        -moz-box-shadow: 0 0 15px -2px #00000030;
        box-shadow: 0 0 15px -2px #00000030;
        border-radius: 8px;
        width: 70%;
        min-width: 400px;
        max-height: 680px;
        overflow: auto;
        -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.35);
        box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.35);
        max-width: 600px;

        @media (max-width: 1268px) {
            width: 100%;
        }

        @media (max-width: 900px) {
            max-height: 320px;
            min-width: 276px;
        }

        & .form-item {
            margin-bottom: 0 !important;
        }
    }

    &__entity-fields-inputs-item {
        width: 100%;
        display: flex;
        align-items: flex-end;
        position: relative;
        flex-wrap: wrap;
        margin-top: 8px;
    }


    &__entity-fields-filters-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}