.tmto-codes {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    min-height: 100px;
    min-width: 170px;
    justify-content: center;
    align-items: center;
}
.tmtoc-item {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}