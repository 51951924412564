@import '../../../../../shared/styles/Colors.scss';

* {
    font-family: "Golos Text" !important;
}

.my-react-calendar {
    background: rgba(203, 170, 255, 0.158);
    border-radius: 8px;
    border: 1px solid $primary;
    border-collapse: collapse;
    padding-bottom: 8px;
}

.my-react-calendar .react-calendar__month-view__days__day {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px !important;
    color: gray
}

.react-calendar__month-view__days__day--weekend {
    color: red !important
}

.react-calendar__month-view__weekdays__weekday {
    margin-bottom: 8px;
    background: $primary;
    color: white;
}

abbr {
    text-decoration: none;
    position: relative !important;
    z-index: 0 !important;
}

.react-calendar__navigation__arrow {
    display: none !important;
}

.event-day {
    background: none !important;
    color: white !important;
    border-radius: 8px;
    font-weight: 600;
}

.react-calendar__month-view__days__day {
    &:hover {
        cursor: none;
        background: $primary !important;
        color: white !important;
        border-radius: 8px;
    }

    &:focus {
        cursor: none;
        background: $primary !important;
        color: white !important;
        border-radius: 8px;
    }

    &:active {
        cursor: none;
        background: $primary !important;
        color: white !important;
        border-radius: 8px;
    }
}

.react-calendar__navigation__label__labelText {
    color: gray;
    font-weight: 500;
}

.react-calendar__navigation__label {
    pointer-events: none;
}

.react-calendar__tile--active {
    background: inherit !important;
}

.react-calendar__month-view__days__day {
    position: relative;
}

.react-calendar__tile--now {
    border-radius: 8px;
    border: 1px solid rgb(179, 77, 238) !important;
    border-collapse: collapse;
    color: white !important;
    background: rgba(250, 40, 239, 0.719) !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0 !important;
    pointer-events: none;
}


.calendar-popup-container .ant-tooltip-content {
    width: fit-content !important;
}
