.short-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    padding: 0px 16px;
    border-radius: 7px;
    cursor: pointer;
    user-select: none;


    &:hover {
        background-color: #00000009;

        .short-profile__avatar {
            background: white !important;
        }
    }

    &:active {
        background-color: #00000005;
    }

    &__avatar {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: black;
        background: #f4f4f4;
        height: clamp(30px, 10vw, 44px);
        width: clamp(30px, 10vw, 44px);
    }

    &__name {
        margin-left: 8px;
        font-size: clamp(9px, 10vw, 15px);
        font-weight: 500;

        @media (max-width: 1000px) {
            display: none;
        }
    }
}

.notification-dashboard {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0px 4px;
    cursor: pointer;

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        color: black;


    }

    &:hover {
        .notification-dashboard__icon {
            background-color: white;
            color: var(--primary);
        }
    }
}