@import "../../../../shared/styles/Colors.scss";

.form-list-item {
  padding: 15px;
  background-color: white;
  border-radius: 9px;
  font-size: 14px;
  position: relative;
  height: 100%;

  &__header {
    display: flex;
    width: 100%;

    justify-content: space-between;

    &__text {
      overflow-wrap: break-word;
      font-weight: 500;
      overflow: hidden;
    }

    &__tag {
      display: flex;
      height: fit-content;
    }
  }

  &__title {
    display: block;
    font-size: 16px;
    text-align: left;
    line-height: 14px;
    letter-spacing: -0.3px;
    margin-bottom: 8px;
    font-weight: 500;
    color: #4f4f4f;
  }

  &__tag > .ant-tag {
    text-align: center;
    height: fit-content;
  }

  &__desc {
    color: #4f4f4f;
  }

  &__assigned-user {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  &__assigned-user > .form-item__user {
    display: flex;
    background-color: #eeeeee;
    padding: 4px;
    margin-left: 8px;
    border-radius: 4px;
    cursor: pointer;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 100%;

    &__body {
      overflow: hidden;
      overflow-wrap: break-word;
      color: #4f4f4f;
    }
  }

  &__comment > span {
    color: #4f4f4f;
  }

  &__comment {
    width: 60%;

    color: #979797;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__additional {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    user-select: none;
    color: #979797;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #888888;
    }

    &:active {
      color: $primary;
    }
  }
}
