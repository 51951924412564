.editable-card {
    background-color: white;
    width: 100%;
    padding: 18px;
    border-radius: 10px;
    position: relative;

    @media (min-width: 475px) and (max-width: 651px) {
        max-width: none;
    }

    @media (max-width: 475px) {
        max-width: none;
    }

    &__buttons {
        position: absolute;
        right: 10px;
        top: 8px;
    }
}

.clickable {
    cursor: pointer;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    }
}

// @media (max-width: 962px) {
//     .editable-card {
//         background-color: white;
//         width: 100%;
//         padding: 18px;
//         border-radius: 10px;
//         position: relative;

//         &__buttons {
//             position: absolute;
//             right: 10px;
//             top: 8px;
//         }
//     }
// }
