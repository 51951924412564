.user-page {
    display: flex;
    min-height: 100%;
    
    padding-bottom: 15px;
    align-items: flex-start;
    @media (max-width: 475px) {
        justify-content: center;
    }
    @media (max-width: 800px) {
        flex-direction: column;
    }
    &__qr-code-list{
        margin-left: 10px;
        @media (max-width: 800px) {
            margin-left: 0px;
            margin-top: 10px;
            justify-content: center;
        }
    }

}