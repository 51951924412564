@import "../../../styles/Colors.scss";

.card-with-head {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;

    &__title {
        padding: 6px 6px;
    }

    &__body {
        height: 100%;
        padding: 9px;
    }
}

.colored {
    color:white;
    background-color: var(--primary);
}

.bordered {
    border: 1px solid;
}
