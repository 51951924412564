.filter-fields--modal {
    display: flex;
    justify-content: center;
    min-width: 220px;
}

.filter-fields {
    background-color: white;
    border-radius: 8px;
    height: fit-content;
    justify-content: space-around;
    padding: 8px;
    width: calc(100% - 10px);
    width: fit-content;

    &__body {
        display: flex;
        flex-wrap: wrap;
        padding-right: 8px;
    }

    &__body--modal {
        flex-direction: column;
        width: 100%;
    }

    &__body--modal>.filter-fields__item {
        margin-right: 0;
        width: 100%;
    }

    &__body--modal .filter-fields__item:last-child {
        width: 100%;
        justify-content: center;
        flex-wrap: wrap-reverse;
    }

    &__item {
        flex-basis: auto;
        display: flex;
        align-items: center;
        margin-right: 16px;
        width: 150px;
        flex-grow: 1;
    }
}