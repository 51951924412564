.recover-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__buttons {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
    }
}