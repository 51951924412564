.confirm-card {
    max-width: 600px;
    width: 100%;
    background: white;
    padding: 18px;
    border-radius: 10px;
    box-sizing: border-box;

    &__title {
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        display: flex;
        justify-content: space-between;
    }

    &__title > .ant-tag {
        font-size: 16px;
        display: flex;
        align-items: center;
        font-weight: 400;
    }

    &__body {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 16px;
        width: 100%;
    }

    &__user {
        display: flex;
        background-color: #eeeeee;
        padding: 4px;
        width: fit-content;
        text-wrap: nowrap;
        margin-left: 8px;
        border-radius: 4px;
        cursor: pointer;
    }

    &__buttons {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
    }

    &__buttons--reverse {
        margin-top: 30px;
        display: flex;
    }

    &__comment {
        font-size: 18px;
        font-weight: 500;
        text-align: left;
    }

    &__comment > .ant-input {
        margin-top: 10px;
    }

    &__comment--info {
        color: gray;
        font-size: 14px;
        text-align: left;
    }
}
