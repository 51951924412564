.template {
    display: flex;
    flex-direction: column;
    // justify-content: start;
    align-items: center;
    height: fit-content;

    & #dragImage {
        background-color: red !important;
    }


    &__item {
        position: relative;
        display: flex;
        flex: 1 1 100%;
        min-height: fit-content;
        margin: 50px 70px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        border-radius: 10px;
        box-sizing: border-box !important;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        }
    }

    &__item--action-button {
        color: #ff9c2b;
        padding: 0 !important;
        min-height: 0 !important;
        height: fit-content !important;

        &:hover {
            background: none !important;
            color: rgb(255, 217, 0) !important;

        }
    }
}

.template-editor--head {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__version {
        margin-left: 10px;
        width: 350px;
    }

}

.template-editor {
    min-width: 100%;
    width: fit-content;
    // overflow: scroll;
    min-height: 100%;
}