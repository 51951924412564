.card-with-aside {
    width: 100%;
    background: #ffffff;
    height: 100%;
    position: relative;
    border-radius: 8px;

    &__container {
        min-height: 100px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    &__title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__body {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 16px 36px 16px 16px;
        width: 100%;

        @media (max-width: 475px) {
            width: 60%;
        }
    }

    &__description {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        color: gray;
        width: fit-content;
    }

    &__description > * {
        margin: 5px 0;
    }

    &__actions {
        position: absolute;
        right: 8px;
        top: 8px;
        color: black;
    }
}

// .triangle-left {
//     position: absolute;
//     right: 0;
//     width: 0;
//     height: 0;
//     border-top: 0px solid transparent;
//     border-right: 185px solid;
//     border-bottom: 185px solid transparent;

// }
