.tm-operation {
    width: 100%;
    margin-right: 10px;
    display: flex;
    justify-content: center;

    &:last-child{
        margin-right: 0px;
    }
    &__content{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 100%;
        padding: 8px;
        display: flex;
        justify-content: space-between;
    }
    &__content--small{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 100%;
        padding: 2px;
        display: flex;
        justify-content: space-between;
    }
    &__arrow, &__content,&__arrow--small, &__content--small {
        -webkit-transition: background-color 0.3s ease-out;
        -moz-transition: background-color 0.3s ease-out;
        -o-transition: background-color 0.3s ease-out;
        transition: background-color 0.3s ease-out;
    }
    &__arrow {
        margin-left: -2px;
        padding: 8px;
        -webkit-clip-path: polygon(30% 0, 100% 50%, 30% 100%, 0 100%, 0 0, 0 0);
        clip-path: polygon(30% 0, 100% 50%, 30% 100%, 0 100%, 0 0, 0 0);        
    }
    &__arrow--small{
        margin-left: -2px;
        padding: 8px;
        -webkit-clip-path: polygon(30% 0, 100% 50%, 30% 100%, 0 100%, 0 0, 0 0);
        clip-path: polygon(30% 0, 100% 50%, 30% 100%, 0 100%, 0 0, 0 0);        
    }
}

.tm-operations-list{

    width:100%;
    display: flex;
    box-sizing: border-box;
    &__items{
        width:100%;
        display: flex;
        box-sizing: border-box;
        justify-content: space-around;
        padding-bottom: 3px;
        overflow-y: auto;
        &::-webkit-scrollbar:horizontal {
            height: 5px;
        }
    }
}

.tm-skeleton .ant-skeleton-paragraph > li {
    padding-top: 0px !important;
}
.tm-operation-skeleton .ant-skeleton-paragraph > li {
    padding-top: 0px !important;
    height: 35px !important;
}