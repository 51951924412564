.variable-create-button {
    @media (max-width: 475px) {
        display: flex;
        justify-content: center;
    }
}

.variable-create-button--empty {
    display: "flex";
    justify-content: center;
}
