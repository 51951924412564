.entity-field-updating-form {
    &__options>.form-item {
        margin-bottom: 4px !important;
        margin-top: 4px !important;
    }

    &__options>.form-item:first-child {
        margin-top: 12px !important;
    }

    &__datatype-description {
        font-size: 12px;
        margin-top: 4px;
        color: #4e4e4e;
    }
}