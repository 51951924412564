.entity-create-button {

    @media (max-width: 415px) {
        display: flex;
        justify-content: center;
    }
}

.entity-create-button--empty {
    display: flex;
    justify-content: center;
}
