.qr-scanner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 999;
    
    &__device-choose {
        // display: flex;
        // flex-direction: row;
    }

    &__device-choose-label {
        white-space: nowrap;
        margin-bottom: 16px;
    }

    &__video-container {
        position: relative;
        margin: 8px;
        min-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__video-text {
        position: absolute;
        font-size: 1.8em;
        text-align: center;
        color: white;
        background: rgb(0, 0, 0, 0.4);
        width: 100%;
        padding: 16px 8px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }
}


.video-window {
    position: relative;

    &__full {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
    }

    &__full-button {
        position: fixed;
        right: 20px;
        bottom: 20px;
        z-index: 1000;
        color: #FFF;
        font-size: 26px;
    }
}

.qr-scanner-hide {
    opacity: 0.2;
    transition: 0.5s;
    animation-duration: 0.5s;
}

.qr-scanner-show {
    opacity: 1;
    transition: 0.5s;
    animation-duration: 0.5s;
}

.qr-scanner-info-block {

    padding: 15px;
    background-color: #FFF;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;

    &__fixed {
        position: fixed;
    }

    &__absolute {
        position: absolute;
    }
}

.success-block {
    position: absolute;
    padding: 15px;
    background-color: #FFF;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
}

.success-block {
    opacity: 0.2;
}

.success-block-show {
    opacity: 1;
    transition: 0.5s;
}

.success-block-hide {
    opacity: 0;
}

.failed-block {
    position: absolute;
    padding: 15px;
    background-color: #FFF;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
}

.failed-block {
    opacity: 0.2;
}

.failed-block-show {
    opacity: 1;
    transition: 0.5s;
}

.failed-block-hide {
    opacity: 0;
}