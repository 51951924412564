.form-option-item {
    margin-bottom: 15px;
    width: 100%;
    &__label {
        font-size: 14px;
        margin-bottom: 1px;
        font-weight: 600;
    }

    &__input {
    }
}
