.forms-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-inline-start: 0px;
    &__item {
        list-style-type: none;

        margin-right: 10px;
        margin-bottom: 10px;
        width: 500px;
    }

    &__skeletons {
        display: flex;
        flex-direction: column;
    }

    &__item--skeleton {
        margin-bottom: 20px;
    }
}

.form-header {
    margin: 10px;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.3px;
}
