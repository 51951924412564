.field-type-create-button {
    min-width: 300px;

    @media (max-width: 475px) {
        display: flex;
        justify-content: center;
    }
}

.field-type-create-button--empty {
    display: flex;
    justify-content: center;
}
