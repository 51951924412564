.modal-title {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    font-size: clamp(17px, 3vw, 20px);
}

.text-with-label {
    margin-bottom: 10px;

    &__label {
        color: #a1a1a1;
    }

    &__text {
        padding-left: 0px !important;
        font-weight: 600;
        color: black !important;
        background-color: rgba(255, 255, 255, 0) !important;
    }

    &__error {
        font-size: 11px;
        color: red;
    }
}

.error-text {
    margin-top: -2px;
    font-size: 11px;
    color: red;
}
