.entities-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    
    @media (max-width: 475px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    &__item {
        margin-right: 10px;
        margin-bottom: 10px;
        width:100%;
        max-width: 450px;
       
    }

    &__skeletons {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    &__item--skeleton {
        margin-right: 10px;
        margin-bottom: 10px;
        width:100%;
        max-width: 450px;
    }
}

.entity-item{
    width: 100%;
    &__buttons{
        position: absolute;
        right: 10px;
        top: 8px;
    }
}