.agreement-additional {
    margin-top: 20px;
    font-size: 14px;

    &__paragraph {
        display: flex;
        color: gray;
    }

    &__paragraph > span {
        margin-right: 4px;
        color: gray;
    }

    &__paragraph-expired > span {
        font-weight: 500;
        color: #d10202;
    }
}

.agreement-additional > h3 {
    font-weight: 500;
    margin: 0;
}
