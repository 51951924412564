.alert-notifications {
    

    &__item{
        margin-bottom: 15px;
        padding: 16px;
        background-color: white;
        border-radius: 9px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        width: "auto";
        &__title{
            font-weight: 600 !important;
            font-size: 16px !important;
            line-height: 1.4 !important;
            margin-bottom: 10px;
        }
        &__description{
            color:rgba(0, 0, 0, 0.88)
        }
 }
}

.alert-notifications--overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    inset: 0;
    z-index: 99998;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    transition:  0.3s;
    animation-duration:  0.3s;
}