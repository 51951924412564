.entity-field-item {
    padding: 10px;
    margin: 0 20px 20px 0;
    border-radius: 8px;
    background: #fff;
    width: 200px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #dfdfdf;
    flex-grow: 3;

    &__text-labels {
        width: 200px;
    }

    &__buttons {
        height: min-content;
        text-align: right;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}
.entity-field-list-item {
    padding: 10px;
    border-radius: 8px;
    background: #fff;
    width: 100%;
    justify-content: space-between;
    border: 1px solid #dfdfdf;
    display: flex;
    align-items: flex-start;
    &__text-labels {
        flex-wrap: wrap;
        display: flex;
        width: 100%;
        &__info{
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 200px;
            margin-right: 10px;
            &__is-name{
                font-weight: 500;
                color: var(--primary);
            }
            &__is-desc{
                color: var(--secondary);
            }
        }
        &__item{
            margin-right: 10px;
            width: 100%;
            max-width: 200px;
        }
    }

    &__buttons {

        flex-direction: row;
    }
}
.entity-field-item--small {
    width: 150px;
}

.border-color {
    border: 1px solid rgb(255, 177, 43);
}
