
.create-edit-entity-row {
    position: relative;
    padding-bottom: 0px;
    &__container {
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: auto;
        scrollbar-gutter: stable both-edges;
    }

    &__body {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        flex-direction: column;
        &__content{
            display: flex;
        }
        @media (max-width: 900px) {
            >.ant-tabs {
                width: 100% !important;
                box-sizing: border-box;
            }

            flex-wrap: wrap;
            width: 100%;
        }
    }

    &__actions-container {
        position: absolute;
        display: flex;
        justify-content: center;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        box-sizing: border-box;
        background: white;
        box-shadow: 0px 0px 0px #888, 0px 1px 5px #888;
        padding: 16px 0 8px 0;
    }
}