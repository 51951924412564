.PDF {
    --dark-color: rgb(50, 54, 57);
    display: flex;
    width: 100%;
    flex-direction: column;
    background: #525659;
    padding: 0 !important;
    height: 100%;
    overflow: hidden;



    &__page-container {
        height: 24px;
    }

    &__container {
        padding: 0 !important;
        margin: 0 !important;
        flex: 1 1 auto;
        overflow: auto;
    }

    &__header {
        position: sticky;
        width: 100%;
        top: 0;
        font-size: 14px !important;
        font-weight: 300;
        padding: 12px;
        background-color: var(--dark-color);
        color: white;
        z-index: 100;
        -webkit-box-shadow: 0px 4px 14px -1px rgba(0, 0, 0, 0.41);
        -moz-box-shadow: 0px 4px 14px -1px rgba(0, 0, 0, 0.41);
        box-shadow: 0px 4px 14px -1px rgba(0, 0, 0, 0.41);

    }

    &__vertical-separator {
        width: 1px;
        height: 15px;
        background: rgba(255, 255, 255, 0.507);
        margin-left: 8px;
        margin-right: 8px;
    }

    &__header-left-side {
        flex: 1 1 33%;
        min-width: 200px;
    }

    &__file-name {
        text-align: left;
        font-size: 1em;
        color: white;
        padding: 0;
        width: 100%;
        margin: 0 4px 0 0;
        font-weight: 300;
        word-break: break-all;


    }

    &__header-center {
        flex: 1 0 33%;
    }

    &__header-right-side {
        flex: 1 1 33%;
    }

    &__document-container {
        justify-content: center;
        flex: 1 1 100px;
        overflow: auto;
        scrollbar-gutter: stable both-edges;

        &::-webkit-scrollbar {
            width: 14px !important;
        }

        &::-webkit-scrollbar-thumb {
            background: #525659 !important;
            border-radius: 0 !important;
            border: 2px solid var(--dark-color);

        }

        &::-webkit-scrollbar-track {
            border-radius: 0 !important;
            background: var(--dark-color) !important;
            border: 0px solid #eee;
        }
    }

    &__document {
        min-width: fit-content;
        height: fit-content;
        background: none;
        padding-top: 12px;
        padding-bottom: 16px;

        & .ant-result-title {
            color: white
        }
    }

    &__page {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__scaling {
        font-size: 14px !important;
        color: black;
    }

    &__controller-input {
        min-height: 0 !important;
        text-align: center !important;
        color: white !important;
        border-radius: 0 !important;
        border: none !important;
        height: 24px !important;
        padding: 0px !important;
        margin: 0 !important;

        &--page-input {
            width: 32px;
            background: black !important;
        }

        &--scale-input {
            background: black !important;
            width: 48px;
            padding: 0px 4px;
        }
    }

    &__scaling-button {
        color: white !important;
        height: 20px !important;
        width: 40px !important;
        padding: 0 !important;
        border-radius: 50% !important;

        &:first-child {
            margin: 0 4px 0 0px;
        }

        &:last-child {
            margin: 0 0px 0 4px;
        }

        &:hover {
            background: rgba(87, 87, 87, 0.144) !important;
        }
    }

    &__pages-list {
        background: var(--dark-color);
        width: fit-content;
        padding: 0 20px 8px 20px;
        overflow: auto;
        flex: 1 1 100%;

        scrollbar-gutter: stable both-edges;

        &::-webkit-scrollbar-thumb {
            background-color: #525659 !important;
            border-radius: 0 !important;
            border: 1px solid var(--dark-color);
        }

        &::-webkit-scrollbar-track {
            background: var(--dark-color) !important;
            border-radius: 0 !important;
        }
    }

    &__pages-list-container {
        height: 100%;
        box-sizing: content-box;
    }

    &__pages-list-page {
        background: rgb(50, 54, 57);
        margin-top: 8px;
        padding: 4px;
        border-radius: 4px;

        & .react-pdf__Page {
            border-radius: 4px;
            overflow: auto;
        }

        scale: 1;
        -webkit-transition: scale 0.2s;
        transition: scale 0.2s;


        &:hover {
            cursor: pointer;
            scale: 1.05;
            -webkit-transition: scale 0.2s;
            transition: scale 0.2s;
            background: rgba(180, 180, 180, 0.486);
        }


    }



    &__pages-list-page--active {
        background: rgba(180, 180, 180, 0.486);
    }
}

@keyframes scaleIn {
    0% {
        scale: 1
    }

    100% {
        scale: 1.05
    }
}

@keyframes scaleOut {
    0% {
        scale: 1.05
    }

    100% {
        scale: 1
    }
}