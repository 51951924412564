.action-card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;

    &__actions {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    &__title {
        font-size: 13px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    &__children {
    }
}
