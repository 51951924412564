.bizproc-action__body {
    border: 1px solid rgb(195, 195, 195);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: none;
    color: #555555;
    position: relative;
}

.bizproc-action__title {
    padding: 8px;
    border: 1px solid;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    font-weight: 500;
    margin-bottom: -1px;
}

.bizproc-action-title--done {
    background-color: #f0f5ff;
    color: #1d39c4;
    border-color: #adc6ff;
}

.bizproc-action-title--success {
    background-color: #f6ffed;
    color: #52c41a;
    border-color: #b7eb8f;
}

.bizproc-action-title--danger {
    background-color: #fff2f0;
    color: #ff4d4f;
    border-color: #ffccc7;
}

.bizproc-action-title--pending {
    background-color: #e6f4ff;
    color: #1677ff;
    border-color: #91caff;
}

.bizproc-action__select-child {
    font-size: 14px;
    display: block;
    margin-left: 4px;
}

.bizproc-action__select-parent {
    display: flex;
    align-items: center;
}

.bizproc-action__actions {
    position: absolute;
    top: 8px;
    right: 16px;
    font-size: 16px;
}
