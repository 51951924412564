.custom-event-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content !important;
    min-height: fit-content !important;
    align-items: flex-start;

    &__content {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        min-height: fit-content;
    }

    &__form-input {
        display: flex;
        align-items: center;
        min-height: 24px !important;
        height: fit-content !important;

    }

    &__additional-info {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: end;
        width: 40%;
        white-space: wrap;
        text-wrap: wrap;
    }

    & .select-value {
        min-height: fit-content !important;
        padding-right: 0;
        height: 100% !important;
        white-space: wrap;
        width: 100%;
    }

    & .form-read-value {
        font-size: 12px !important;
    }

    & .ant-skeleton-paragraph {
        margin: 0 !important;
    }

    & .ant-skeleton-paragraph li {
        padding: 0 !important;
        height: 100%;
    }

    &__description-wrapper {
        display: flex;
        width: 40%;

        & .custom-event-wrapper__form-input {
            width: 100% !important;
        }

        & .form-read-value {
            width: 100% !important;
            text-overflow: ellipsis !important;
            overflow: hidden !important;
            text-wrap: nowrap !important;
        }
    }
}