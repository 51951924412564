.estimation-card {
    box-shadow: none;
    width: fit-content;
    min-width: 160px;
    box-shadow: none !important;

    @media (max-width: 768px) {
        width: 160px;
    }

    .ant-card-body {
        padding: 0 8px 0 8px !important;


    }

    & .ant-statistic-content {
        height: fit-content !important;
        font-size: unset !important;
    }

    .ant-statistic-content * {
        font-size: 14px !important;
        vertical-align: top !important;
        word-break: normal !important;
        text-wrap: wrap !important;
    }

    .ant-statistic-content-value {
        width: 100% !important;
        word-break: break-all;
        white-space: wrap;
    }

    .ant-statistic-title {}
}

.estimation-card--with-select .ant-statistic-title .select-value {
    & * {
        font-size: 12px !important;
    }

    height: 22px !important;
    min-height: 0 !important;
    margin: 0 !important;
}

.estimation-title {
    font-size: 12px;
    color: inherit;
    text-wrap: nowrap;

}