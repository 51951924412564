.entity-field-item {
    padding: 10px;
    margin: 0 20px 20px 0;
    border-radius: 8px;
    background: #fff;
    width: 200px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #dfdfdf;
    flex-grow: 3;

    &__text-labels {
        width: 200px;
    }

    &__buttons {
        text-align: right;
    }
}

.entity-field-item--small {
    width: 150px;
}

.border-color {
    border: 1px solid rgb(255, 177, 43);
}

.disabled {
    opacity: 0.5;
    border: none;
}
