.view-pattern-item {
    padding: 4px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 2px;
    border-radius: 8px;
    background: white;

    &__title {
        display: flex;

        & .form-item__body {

            & .ant-skeleton-paragraph>li {
                padding: 0;
                margin: 0;
                height: 16px !important;
            }
        }
    }
}