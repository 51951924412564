.tmto-drawer-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    &__buttons{ 
      position: absolute;
        display: flex;
        justify-content: center;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        box-sizing: border-box;
        background: white;
        box-shadow: 0px 0px 0px #888, 0px 1px 5px #888;
        padding: 16px 0 18px 0;
    }
}