.create-edit-entity-row__history {
    width: 60%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 16px;
    margin-left: 16px;
    background: white;
    border-radius: 16px;
    height: fit-content;
    flex-grow: 1;
    width: fit-content;

    @media (max-width: 900px) {
        margin-left: 0;
        width: 100%;
    }
}