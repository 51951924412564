@import "@shared/styles/Colors.scss";

.signin-form {

    width: 100%;


    &__image-container {
        width: 150px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__image-frame {
        padding: 10px;
        border-radius: 8px;
    }

    &__image-description {
        font-size: 14px;
        font-weight: 600;
        text-align-last: center;
    }



    .username-form-forgot {
        float: right;
    }

    .ant-col-rtl .username-form-forgot {
        float: left;
    }

    #logo {
        fill: tomato;
    }
}

.username-form-button {
    width: 100%;
}

