.dnd-block {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.dnd {
    margin-top: 16px;
    padding-right: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    &__columns {
        display: flex;
        flex-direction: row;
    }

    &__column-title {
        background: rgb(243, 243, 243, 0.5);
        color: gray;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        margin-bottom: 8px;
        border-radius: 8px;
        margin-right: 3px;
    }

    &__column {
        width: 300px;
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }
}