@import "../shared/styles/Cards.scss";
@import "../shared/styles/Global.scss";


/* width */
::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar:horizontal {
    height: 7px;
}


/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f4f4f4c1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--scrollbarcolor);
    border-radius: 10px;
}

.main-layout {
    background: linear-gradient(337deg, rgb(143 133 232 / 56%), rgba(143, 133, 232, 0.1490196078));
    padding: 10px;
    min-height: 620px;
    height: 100vh;

}

.main-inner-layout {
    overflow: hidden;
    font-size: 16px;
    border-radius: 25px;
    background: linear-gradient(146deg, rgb(255 255 255 / 87%), rgb(255 255 255 / 64%)) !important;


}

.site-layout {
    background: none !important;
}

.site-content-layout {
    position: relative;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 7px;
    }


    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #f4f4f4c1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--primary);
        border-radius: 10px;
    }

}

.ant-menu-title-content {
    font-size: 14px;
}

.ant-picker-input {
    min-height: 30px;
}

.ant-picker-input:focus {
    background-color: #8f85e817;
    border: none;
}

.ant-select-selector {
    min-height: 38px;
}

.ant-select-single {
    min-height: 38px;
}

.ant-select-selector:focus {
    background-color: #8f85e817;
    border: none;
}

.ant-select .ant-select-arrow {
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    margin-top: 0px;
}

.ant-input {
    min-height: 38px;
}

.ant-btn {
    min-height: 38px;
}