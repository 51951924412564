.entity-list {
    display: flex;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 1200px) {
        flex-direction: column;
    }

    &__item {
        position: relative;
        background: white;
        align-items: stretch;
        margin-bottom: 8px;
        width: 32%;

        @media (max-width: 1200px) {
            width: 100%;
        }
    }
}