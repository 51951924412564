.stages-entity-row {
    display: flex;
    justify-content: center;
    width: 100%;



    & span {
        display: inline-flex;
        width: 100%;
    }
}

.stage-entity-row {
    width: 100%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    height: 100%;



    &:last-child {
        margin-right: 0px;
    }

    &__content {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 100%;
        padding: 8px;

        @media (max-width: 900px) {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        & .ant-typography {
            @media (max-width: 1500px) and (min-width: 901px) {
                display: inline-block;
                font-size: 12px;
                vertical-align: middle;
                text-wrap: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }
        }


    }

    &__arrow,
    &__content {
        -webkit-transition: background-color 0.3s ease-out;
        -moz-transition: background-color 0.3s ease-out;
        -o-transition: background-color 0.3s ease-out;
        transition: background-color 0.3s ease-out;
    }

    &__arrow {


        @media (min-width: 901px) {
            clip-path: polygon(30% 0, 100% 50%, 30% 100%, 0 100%, 0 0, 0 0);
            margin-left: -2px;
            padding: 8px;
            -webkit-clip-path: polygon(30% 0, 100% 50%, 30% 100%, 0 100%, 0 0, 0 0);
        }
    }
}

.final-stages-modal {
    &__button {
        width: 100%;
        display: flex;
    }
}

.stages-timeline {
    & .ant-timeline-item {
        padding-bottom: 8px !important;
        height: fit-content !important;
    }

    & .ant-timeline-item-last {
        padding-bottom: 8px !important;
    }
}

.stages-popover {
    & .ant-popover-inner {
        padding-top: 16px !important;
        padding-bottom: 0 !important;
    }
}