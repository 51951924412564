.tmton-item{ 
    
    width: 100%;
    &__content{

    }
    &__actions{
        
    }
}

