.capabilities-editor-page {
    height: 100%;
    width: 100%;
    padding-left: 8px;
    min-width: 1300px;
    overflow-x: auto;
    position: relative;
    display: flex;
    flex-direction: column;

    &__container {
        flex: 1 1 100%;
    }
}