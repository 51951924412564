.fill-action-form-builder-skeletons {
    &__item {
        margin-bottom: 16px;
    }
}

.fill-action-form-builder-skeletons .ant-skeleton-paragraph {
    margin: 8px !important;
    padding: 0 !important;
}

.fill-action-form-builder-skeletons__label .ant-skeleton-paragraph {
    height: 16px !important;
}

.fill-action-form-builder-skeletons__value .ant-skeleton-paragraph {
    height: 24px !important;
}