.fields-list-creator {
    width: 100%;

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div {
            margin-right: 10px;
            width: 100%;
            padding: 4px 10px;

            border: 1px solid #d9d9d9;
            border-radius: 3px;
        }
    }
}
.field-with-entity {
    border:1px solid rgb(170, 166, 166);
    border-end-end-radius: 10px;
    border-end-start-radius: 10px;
    border-top: none;
    padding: 10px;
}
