.view-pattern-edit-form {
    display: flex;
    flex-direction: column;

    &__items {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        padding-left: 2px !important;
    }

    &__pattern {
        display: flex;
        margin-bottom: 16px;

    }
}