.entity-rows-table-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    overflow: auto;
    height: 100%;
    flex: 1 1 200px;
    background: white;
}

.entity-rows-table {
    & .ant-table-cell {
        padding: 4px 7px !important;
    }

    &__pagination-container {
        position: sticky;
        // justify-content: space-between;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 4px 15px;
        background: white;
        z-index: 2;
        margin-top: 20px;
        display: flex;
        align-items: center;
        width: 100%;

        & .ant-pagination {
            display: flex !important;
            flex-wrap: nowrap !important;
        }

        .ant-select-selector {
            min-height: 0px !important;
        }

        .ant-select-single {
            min-height: 0px !important;
        }


        @media (max-width: 899px) {
            padding: 0;
            padding-bottom: 4px;
            padding-top: 4px;
        }

        @media (max-width: 899px) {
            // justify-content: center;
            margin-top: 0;
            flex-wrap: wrap;
        }
    }
}



.entity-table-row {
    padding: 0 !important;
    cursor: pointer;
    background: white;

}

.table-column {
    box-sizing: border-box !important;

}