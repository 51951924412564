.user-roles-header {
    background: var(--info);
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &__title {
        margin: 0px !important;
        margin-left: 16px !important;
        font-weight: 400 !important;
        color: white !important;
        vertical-align: middle !important;
    }

    &__create-button {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
    }
}