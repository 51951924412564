.user-role-card {
    border: none !important;
    padding: 0 0 0 8px !important;
    width: 100%;
    background: white;

    & * {
        font-weight: 300 !important;
    }

    &:hover {
        cursor: pointer;
        background: #fafafa;
    }

    &__container {
        width: 90%;
    }

    &__text {
        text-wrap: wrap;
    }

    &__priority-value {
        margin-left: 4px;
        color: gray
    }
}