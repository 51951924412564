@for $i from 1 through 4 {
	$width: 200px * $i;
	.max-w-#{$i} { 
		max-width: $width;
	}
}
@for $i from 1 through 4 {
	$width: 25% * $i;
	.procent-w-#{$i} { 
		max-width: $width;
	}
}
.drawer-default { 
    transition: all 0.2s linear;
    overflow: visible !important;
    height: 100%;
}
.display-inline {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.space-between{
    justify-content: space-between;
}

.link {
    color:var(--primary) !important;
    cursor: pointer;
    &:hover{
        transition:all 0.3s;
        filter: brightness(120%);
    }
  }

.floating-card-element {
    position: absolute;
    top: -10px;
    right: -15px;
}