.bizproc-action__footer {
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.bizproc-action__time {
    font-size: 14px;
}

.bizproc-action__time > span {
    font-weight: 500;
}

.assygnement {
    display: flex;
    align-items: center;
}

.assygnement > span {
    display: block;
    font-weight: 500;
    text-wrap: nowrap;
}
