.category-panel-item > .ant-collapse-header {
    align-items: center !important;
}

.category-panel  {
    
    @media (max-width: 600px) {
        .ant-collapse-content-box {
            padding: 10px !important;
        } 
    }
    
}