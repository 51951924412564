.capabilities-roles-add-button {
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;
    border-radius: 8;
    color: white !important;

    &:hover {
        background: rgba(255, 255, 255, 0.1) !important;
        scale: 1.03;
        transition: 0.1s;
    }
}