@import '../../../../../shared/styles/Colors.scss';

.calendar-toolbar {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
  width: calc(100% - 26px);


  & button {
    background: rgb(143, 133, 232, 0.2) !important;
    color: $primary;
    border: none;

    &:hover {
      background: $primary !important;
      color: white !important;
      opacity: 1 !important;
    }
  }


  &__button-group {
    flex-wrap: wrap-reverse;
    justify-content: flex-start;
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

  }

  &__current-date-nav {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    @media (max-width: 600px) {
      flex-grow: 1;
    }
  }

  &__current-date-nav > button {
    background: rgb(143, 133, 232, 0.2) !important;
    color: $primary;

    &:hover {
      background: $primary !important;
      color: white !important;
      opacity: 1 !important;
    }
  }

  &__current-date {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 220px;
    height: 100%;
    box-sizing: border-box;
    margin-left: 4px;
    margin-right: 4px;
    background: $primary;
    border-radius: 4px;
    font-weight: 300;
    margin-left: 8px;
    margin-right: 8px;
    color: white;

    @media (max-width: 600px) {
      flex-grow: 1;
    }
  }

  &__wrapper-items {
    @media (max-width: 600px) {
      flex-grow: 1;
    }
  }

  &__today-button {
    @media (max-width: 600px) {
      flex-grow: 1;
    }
  }

  &__format-picker .ant-dropdown-menu-item-only-child {
    &:hover {
      color: white !important;
      background: $primary !important;
    }
  }

  &__format-picker .ant-dropdown-menu {
    background: #000 !important;
  }

  &__date-picker {
    margin-right: 8px
  }
}

.rbc-label.rbc-time-header-gutter {
  display: block;
}

.rbc-selected {
  @extend .rbc-label
}
