.tmto-ni-form{
    width: 100%;
   
   
    &__info{
        display: flex;
        width: 100%;
        margin-bottom: 15px;
    }
    &__use-actions{ 
        width: 100%;
        max-height: 470px;
        padding: 10px;
        border-radius: 9px;
        border: 1px solid #ededed;
        overflow-x:hidden;
        overflow-y:auto;
        
    }
}