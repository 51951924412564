.bizproc-templates-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &__item {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 300px;
    }
}

.bottom-actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
