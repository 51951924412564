.delete-entity-field-input {
    font-size: 12px;
    cursor: pointer;
    color: #cccccc;
    margin-left: 8px;
    padding: 0;
    margin: 0;
    min-height: 0 !important;
    height: fit-content !important;
}

.delete-entity-field-input:hover {
    color: rgb(247, 80, 108) !important;
}