#react-doc-viewer {
    display: flex;
    flex: 1 1 100% !important;

    & #proxy-renderer {
        display: flex;
        flex: 1 1 100% !important;

        & #msdoc-renderer {
            display: flex;
            flex: 1 1 100% !important;
        }
    }
}

.Office-viewer {
    --dark-color: rgb(50, 54, 57);
    display: flex;
    width: 100%;
    flex-direction: column;
    background: #525659;
    padding: 0 !important;
    height: 100%;
    overflow: hidden;

    &__header {
        position: sticky;
        width: 100%;
        top: 0;
        font-size: 14px !important;
        font-weight: 300;
        padding: 12px;
        background-color: var(--dark-color);
        color: white;
        z-index: 100;
        -webkit-box-shadow: 0px 4px 14px -1px rgba(0, 0, 0, 0.41);
        -moz-box-shadow: 0px 4px 14px -1px rgba(0, 0, 0, 0.41);
        box-shadow: 0px 4px 14px -1px rgba(0, 0, 0, 0.41);

    }

    &__container {
        padding: 0 !important;
        margin: 0 !important;
        flex: 1 1 auto;
        overflow: auto;
    }
}