.tech-map-tmp-block {
    display: flex;

}

.tech-map-tmp-header {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1;
    padding: clamp(10px, 1vw, 25px);
    border-radius: var(--card-border-radius);
    margin-bottom: 10px;
    width: fit-content;
    &__name{
        font-weight: 500;
        font-size: 16px;
        margin-right: 20px;
    }
}