.carousel-container {
    height: fit-content;

    & .ant-select-selector {
        min-height: 0;
        padding-right: 8px !important;
    }

    & .ant-btn {
        padding: 0 4px 0 4px !important;
    }

    & .ant-select-selection-search {
        display: none !important
    }
}