.condition-group {
    border: none;

    border-radius: 8px;
    background: rgba(0, 0, 0, 0.06);
    padding: 5px 10px;
    margin: 10px 0px;

    &__icon {
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.condition-group > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
}

.condition-group > .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
    padding: 0px;
}

.condition-group-header {
    display: flex;
    align-items: center;

    &__label {
        text-wrap: nowrap;
    }
}

.condition-item {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}
