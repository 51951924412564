.capabilities-roles-table-with-creating {
    height: 100%;
    min-width: 900px;
    width: 100vw;
    background: white;
    border-radius: 8px;
    overflow-x: auto;
    flex: 1 1 auto;

    &__footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 0 1 54px;
        position: sticky;
        bottom: 0;
        padding: 8px;
        background-color: #fafafa;
    }
}