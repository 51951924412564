.input-dropdown {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    background-color: rgb(233, 233, 233);
    transition: height 350ms ease;
    z-index: 9999999;
    overflow-y: auto;

    &__item {
        padding: 10px 20px;
        user-select: none;
        cursor: pointer;
        background-color: rgb(251, 254, 255);

        &:hover {
            background-color: rgb(226, 229, 230);
        }

        &:active {
            background-color: rgb(250, 251, 252);
        }
    }
    
}
.input-dropdown__collapse{
    .ant-collapse-content-box {
        padding: 0 !important;
       } 
}
