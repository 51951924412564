.group-select-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-wrap: nowrap;
    width: 100%;
    min-width: 200px;
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;
    &:hover{
        background-color: rgba(0, 0, 0, 0.06);
    }
    &:active {
        background-color: rgba(0, 0, 0, 0.1);

    }
    &__label {
        text-overflow: ellipsis;
        overflow: hidden
    }
    
}

.group-select-item__label.item-active{
    color:var(--primary)
 }