.user-agreement {
    width: 100%;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__name {
            font-size: 15px;
            margin-right: 10px;
            text-wrap: nowrap;
            overflow: hidden;
            font-weight: 400;
            text-overflow: ellipsis;
        }
    }

    &__desc {
        &__header {
            text-wrap: nowrap;
            overflow: hidden;
            font-weight: 400;
            text-overflow: ellipsis;
        }

        &__body {
            color: #9a9a9a;
            margin-top: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    &__additional {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;

        &__approve-info {
            display: flex;
            align-items: center;
        }
    }
}
