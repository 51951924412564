.bizproc-action__body .ant-skeleton-paragraph {
    margin: 0 !important;
}

.bizproc-action__body .ant-skeleton-paragraph>li {
    margin: 8 !important;
    height: 12px !important;
}

.bizproc-action-title--skeleton {
    border: 1px solid rgb(195, 195, 195);
}