.bizproc-info {
    padding: 16px;
    display: flex;
    width: 100%;

    @media (max-width: 729px) {
        flex-direction: column;
    }
    &__fields {
        display: flex;
        margin-right: 20px;
        flex-direction: column;
        min-width: 600px;
        &__inputs {
            background-color: white;
            margin-top: 20px;
            padding: 20px;
            border-radius: 8px;
            &__actions {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
