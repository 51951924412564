.empty-page {
    min-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__header {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        font-size: 24px;
        margin-bottom: 20px;
    }

    &__button {
        display: flex;
        justify-content: center;
    }
}

.empty-page--hidden {
    display: none;
}
