.reduced-value-shadow {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    -webkit-box-shadow: 0px -55px 49px -20px rgba(255, 255, 255, 1) inset;
    -moz-box-shadow: 0px -55px 49px -20px rgba(255, 255, 255, 1) inset;
    box-shadow: 0px -55px 49px -20px rgba(255, 255, 255, 1) inset;
}