.bizproc__information {
    padding: 20px 40px 20px 20px;
    border-radius: 8px;
    margin-right: 40px;
    width: 100%;
    background: white;
    height: fit-content;
    min-width: 270px;
    position: relative;
    z-index: 2;

    @media (max-width: 729px) {
        width: 100%;
        margin-bottom: 20px;
        flex-wrap: wrap;
    }
}
