.production-page{
    display: flex;
    height: 100%;
    padding-right: 20px;
    width: 100%;
    box-sizing: border-box;
    &__main{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        width: calc(100% - 300px);
        height: 100%;
        &__operations{
            display: flex;
            min-width: 100%;
            margin-bottom: 10px;
            box-sizing: border-box;
        }
    }
    &__bar{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        max-width: 300px;
        width: 100%;
        min-width: 300px;
        &__started{
            height: 100%;
            max-height: 300px;
            overflow-y: auto;
            
        }
        &__printer{
            background-color: white;
            padding: 16px;
            margin-bottom: 15px;
            width:100%;
            max-width: 300px;
            box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1882352941);
            border-radius: 7px;
        }
        &__executable{
            overflow-y: auto;
            margin-bottom: 15px;
            height: 100%;
        }
    }

}