.entity-form-actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 21px;
}


.entity-inputs{
    margin: 0 auto;
    display: grid;
    gap: 10px 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}


@media (max-width: 900px) {
    .entity-form-actions { justify-content:center}
  }