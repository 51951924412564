.tmo-process{
    -webkit-box-shadow: 0 0 7px -2px #00000030;
    -moz-box-shadow: 0 0 7px -2px #00000030;
    box-shadow: 0 0 7px -2px #00000030;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    &__items { 
    
    }
    &__actions{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 20px;
    }
}