.tmtr-select-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    &__add{
        display: none;
        min-height: 21px;
    }
    &:hover{
        .tmtr-select-item__add{
            display:block !important;
        }
    }
}