.capabilities-roles-table {

    &__header {
        display: flex;
        flex: 0 1 50px;
    }

    &__title-container {
        background: var(--primary-5);
        align-items: center;
        flex: 1 1 auto;
        padding-left: 16px;
    }

    &__title {
        padding: 0 !important;
        margin: 0 !important;
        color: white !important;
        font-weight: 400 !important;
    }

    &__header-labels {
        border-bottom: 1px solid #efefef;
        background: #fafafa;
        height: 40px;
    }

    &__cell {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 180px;

        & .ant-select-selection-item {
            line-height: normal !important;
            text-wrap: wrap !important;
        }

        & .ant-select {
            height: 100% !important;
        }

        & .ant-select-selector {
            height: 100% !important;
            padding: 0 !important;
        }


    }

    &__cell--entity {
        display: flex;
        align-items: center;
        padding-left: 16px;
    }

    &__content {
        display: flex !important;
        flex-direction: column;
        width: 100%;
        height: 100%;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    }

    &__scroll-container {
        height: 100%;
        overflow: auto;
        flex: 1 1 200px;
    }

    &__content-container {
        overflow: auto;
        width: 120vw;
        min-width: calc(1920px * 1.2)
    }
}