.form-builder-item {
    padding: 0;
    margin: 0;

    &__divider {
        display: flex;
        justify-content: center;
        flex-direction: row;
        width: 100%;
    }

    &__inputs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__input {
            width: 47%;
            padding: 0;
            margin: 0;
        }
    }
}

.form_item {
    margin: 0;
}
