.role-item{
    padding: 3px;
    &__name{
    }
    &__dot{
        margin-right: 4px;
    }
}

.roles-list{
    &__label{
        padding: 3px;
        padding-left: 0px;
        margin-right: 5px;
    }
}