.tm-info {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px;
    &__operations {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        margin-bottom: 15px;
        background-color: white;
        padding: 15px;
        border-radius: var(--card-border-radius);
    }
    &__main{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        &__info {
            &:not(:last-child) {
                margin-right: 7px;
            }
            &:last-child {
                margin-left: 7px;
            }
            width: 100%;
            background-color: white;
            border-radius: var(--card-border-radius);
            padding: 15px;            
        }
    }
}

.tm-time-intervals{
    &__label{
        font-weight: 500;
        font-size: 15px;
    }
    &__items{
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        &__item{
            margin-bottom: 10px;
          
            padding: 10px;
            display: flex;
            flex-direction: column;
            background-color: rgb(248, 248, 248);
            border-radius: var(--card-border-radius);
        &__name{
            font-weight: 500;
            margin-bottom: 4px;
          
        }
        }
    }
}